import React, { useEffect, useState } from 'react';
import { getInnleggById, generateImageUrl } from '../services/sanity.js';
import { useViewContext } from '../Context/ViewContext.jsx';
import styled from 'styled-components';

const Wrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px 20px 0 20px;

    @media (max-width: 840px) {
        padding: 20px 20px 60px 20px;
    }
`;

const HorizontalCard = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin: 0 auto;
    flex-wrap: wrap;

    h3 {
        margin: 0;
    }

    img {
        width: 100%;
        height: 100%;
        max-width: 300px;
        max-height: 300px;
        object-fit: cover;
        border-radius: 5px;
    }

    div {
        flex: 1;
    }

    @media (max-width: 700px) {

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
`;

const NewsEntry = () => {
    const { language } = useViewContext();

    const [innlegg, setInnlegg] = useState(null);
    const [title, setTitle] = useState(null);
    const id = "d1f5c709-f5ff-4785-bf37-65de2235b192";

    useEffect(() => {
        async function fetchData() {
            const data = await getInnleggById(id);
            setInnlegg(data);
            let title = language === "NO" ? data.noTittel : data.enTittel;
            // remove (newsEntry) from title
            setTitle(title.replace(/\(.*\)/, ''));
        }

        fetchData();
    }, [id, language]);

    if (innlegg) return (
        <Wrapper>
            <h2>{language === "NO" ? "Siste nytt" : "Latest news"}</h2>
            {innlegg && (
                <HorizontalCard>
                    <img src={generateImageUrl(innlegg.hovedBilde.asset._ref)} alt="news" />
                    <div>
                        <h3>{title}</h3>
                        <p>{language === "NO" ? innlegg.noBeskrivelse : innlegg.enBeskrivelse}</p>
                    </div>
                </HorizontalCard>
            )}
        </Wrapper>
    );

    return null; // Ensure component returns null if no data is available yet
};

export default NewsEntry;
