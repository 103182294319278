import { createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

/**
 * Creates and exports a Sanity client.
 */
export const client = createClient({
    projectId: 'prs27zn1',
    dataset: 'production',
    useCdn: true,
    apiVersion: '2023-10-20',
});
const builder = imageUrlBuilder(client);

/**
 * Generates an image URL based on provided reference, with optional width and height.
 * @param {string} _ref - The reference to the image.
 * @param {number|null} [width] - The desired width of the image (optional).
 * @param {number|null} [height] - The desired height of the image (optional).
 * @returns {string} The generated image URL.
 */
export function generateImageUrl(_ref, width = null, height = null) {
    let imageBuilder = builder.image(_ref).auto('format');

    if (width) {
        imageBuilder = imageBuilder.width(width);
    }

    if (height) {
        imageBuilder = imageBuilder.height(height);
    }

    return imageBuilder.url();
}

/**
 * Retrieves posts by category from Sanity.
 * @param {string} category - The category of posts to retrieve.
 * @returns {Promise<Array<Object>>} An array of posts matching the category.
 */
export async function getInnlegg(category) {
    // order by aarstall desc
    const query = `*[_type == "innlegg" && kategori == "${category}" ] | order(aarstall desc)`;
    const result = await client.fetch(query);
    return result;
}

/**
 * Retrieves the latest posts from Sanity.
 * @param {number} [limit=4] - The number of latest posts to retrieve.
 * @returns {Promise<Array<Object>>} An array of the latest posts.
 */
export async function getLatestInnlegg(limit = 4) {
    const query = `*[_type == "innlegg"] | order(_createdAt asc) [0..${limit - 1}]`;
    const result = await client.fetch(query);
    return result;
}


/**
 * Retrieves the main page content from Sanity.
 * @returns {Promise<Array<Object>>} An array containing the main page content.
 */
export async function getHovedsideInnhold() {
    const query = `*[_type == "hovedsideInnhold"]`;
    const result = await client.fetch(query);
    return result;
}

/**
 * Retrieves categories from Sanity.
 * @returns {Promise<Array<Object>>} An array of categories.
 */
export async function getCategories() {
    const query = `*[_type == "kategorier"]`;
    const result = await client.fetch(query);
    return result;
}

/**
 * Retrieves a post (innlegg) from Sanity based on the provided ID.
 * @param {string} id - The ID of the post to retrieve.
 * @returns {Promise<Object>} The post matching the provided ID.
 */
export async function getInnleggById(id) {
    const query = `*[_type == "innlegg" && _id == "${id}"][0]`;
    const result = await client.fetch(query);
    return result;
}

const customSort = (arr) => {
    const wantedOrder = [
        "1iGxZpbv2uESlZ562Bv6Zw", // utdanning
        "ZkDbD4gLvoVK9O0MnZUu1F", // medlemskap
        "oyI8CelMC3vPCC1wJLU8gW", // separatutstillinger
        "oyI8CelMC3vPCC1wJLU7uu", // utsmykking
        "oyI8CelMC3vPCC1wJLU7qw", // gruppeutstillinger
        "ZkDbD4gLvoVK9O0MnZUtxg", // premiering i konkurranser
        "oyI8CelMC3vPCC1wJLU8Uc", // innkjøp
        "oyI8CelMC3vPCC1wJLU8cY", // stipend
        "oyI8CelMC3vPCC1wJLU8Qe", // konsulentarbeid
        "1iGxZpbv2uESlZ562Bv6Yx", // atelier og symposier
        "oyI8CelMC3vPCC1wJLU8Mg", // verv
        "oyI8CelMC3vPCC1wJLU8Ya", // litteratur
        "1iGxZpbv2uESlZ562Bv6W0" // annet
    ];

    return arr.sort((a, b) => {
        return wantedOrder.indexOf(a._id) - wantedOrder.indexOf(b._id);
    });
}
/**
 * Retrieves the CV sections from Sanity.
 * @returns {Promise<Array<Object>>} An array of CV sections.
 */
export async function getcvSeksjon() {
    const query = `*[_type == "cvSeksjon"]`;
    const results = await client.fetch(query);

    function extractFirstYear(aarstall) {
        if (aarstall === "0") return -Infinity;

        const firstYearStr = aarstall.split(/[,/-]/)[0].trim();
        return parseInt(firstYearStr, 10) + (parseInt(firstYearStr, 10) <= 50 ? 2000 : 1900);
    }

    function sortListItemsByAarstall(a, b) {
        const firstYearA = extractFirstYear(a.aarstall);
        const firstYearB = extractFirstYear(b.aarstall);
        return firstYearB - firstYearA;
    }

    for (let result of results) {
        result.listItems.sort(sortListItemsByAarstall);

        // Change "0" to "_" for each listItem
        for (let item of result.listItems) {
            if (item.aarstall === "0") {
                item.aarstall = "_";
            }
        }
    }
    customSort(results);

    return customSort(results);
}
/**
 * Retrieves the "lenker" section from Sanity.
 * @returns {Promise<Array<Object>>} An array containing the "lenker" section.
 */
export async function getLenker() {
    const query = `*[_type == "lenker"]`;
    const result = await client.fetch(query);
    return result;
}
