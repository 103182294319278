
import React, { useState } from "react";
import ImageGallery from "react-image-gallery";
import styled from "styled-components";

const Wrapper = styled.div`
    .image-gallery {
        width: 100%;
    }

    .image-gallery-slide {
        background-color: black;
   }

    .image-gallery-image {
        object-fit: contain;
        height: ${({ $fullscreen }) => ($fullscreen ? 'auto' : '600px')};

        &&:hover {
            cursor: pointer;
        }
    }
`

const Carousell = ({ images }) => {
    const [isFullScreen, setIsFullScreen] = useState(false);
    return (
        <Wrapper $fullscreen={isFullScreen}>
            <ImageGallery
                items={images}
                lazyLoad={true}
                slideDuration={300}
                onClick={(e) => {
                    const imageUrl = e.target.src;
                    if (imageUrl) {
                        window.open(imageUrl, '_blank').focus();
                    }
                }}

                onScreenChange={(e) => { setIsFullScreen(e) }}
            />
        </Wrapper>
    );
};

export default Carousell;
