import { useParams } from 'react-router-dom';
import { getInnleggById, generateImageUrl } from '../services/sanity.js';
import { useEffect, useState } from 'react';
import Carousell from '../Components/Carousell.jsx';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import BackButtonHeader from '../Components/Elements/BackButtonHeader.jsx';
import AnimatedSection from '../Components/Elements/AnimatedSection.jsx';

const Wrapper = styled.div`
    width: 100%;
`

const Description = styled(Typography)`
    padding: 20px 0;
`

const Innlegg = () => {
    const { id } = useParams();
    const [innlegg, setInnlegg] = useState(null);
    const [fetchedImages, setFetchedImages] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const data = await getInnleggById(id);
            setInnlegg(data);
            setFetchedImages([{
                thumbnail: generateImageUrl(data.hovedBilde.asset._ref, 90, 55),
                original: generateImageUrl(data.hovedBilde.asset._ref)
            }]);

            if (!data.andreBilder) return;
            data.andreBilder.forEach((bilde) => { // Note the capital 'E' in forEach
                const imageDetails = {
                    thumbnail: generateImageUrl(bilde.asset._ref, 90, 55),
                    original: generateImageUrl(bilde.asset._ref)
                };
                setFetchedImages((prevImages) => [...prevImages, imageDetails]);
            });
        }
        fetchData();
    }, [id]);

    return (
        <Wrapper>
            <AnimatedSection>
                <BackButtonHeader>{innlegg && innlegg.noTittel}</BackButtonHeader>
                {fetchedImages && <Carousell images={fetchedImages} />}
                {innlegg && <Description >{innlegg.noBeskrivelse}</Description>}
            </AnimatedSection>
        </Wrapper>
    );
}

export default Innlegg;